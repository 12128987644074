<template>
  <!-- Game Type4 (分類版塊) -->
  <div
    :id="'game-' + data.block"
    class="game-type4 my-2"
  >
    <swiper
      ref="gameItem"
      :options="swiperOption"
      class="mySwiper px-4 py-0"
    >
      <swiper-slide
        v-for="item in data.data.navbar"
        :key="item._id"
      >
        <div
          class="game__item--card cursor-pointer rounded"
          :class="[`${item.class}-bg`]"
          @click="goVendorPage(item._id)"
        >
          <div
            class="game-card d-flex justify-center"
          >
            <Icon
              :data="icons[item.class]"
              width="60"
              height="56"
              class="bg--text"
            />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import card from '../../../../assets/icons/vendor/cardB.svg'
import fish from '../../../../assets/icons/vendor/fishB.svg'
import live from '../../../../assets/icons/vendor/liveB.svg'
import lottery from '../../../../assets/icons/vendor/lotteryB.svg'
import slot from '../../../../assets/icons/vendor/slotB.svg'
import esport from '../../../../assets/icons/vendor/eSportB.svg'
import sport from '../../../../assets/icons/vendor/sportB.svg'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // swiper
    swiperOption: {
      slidesPerView: 5,
      spaceBetween: 8,
      centeredSlidesBounds: true,
    },

    icons: {
      card,
      fish,
      live,
      lottery,
      slot,
      esport,
      sport,
    },
  }),

  computed: {
    ...mapGetters(['theme']),
  },

  methods: {
    /**
     * 到 vendor 頁
     * @date 2021-12-10
     * @param {string} vendorID
     */
    goVendorPage(vendorID) {
      this.$router.push({
        name: 'vendor',
        params: {
          id: vendorID,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.h-56px {
  height: 56px;
}

.card-bg{
  background: #dea554;
}
.fish-bg{
  background: #1cbaff;
}
.live-bg{
  background: #fd683c;
}
.lottery-bg{
  background: #febc08;
}
.slot-bg{
  background: #f08108;
}
.esport-bg{
  background: #27dce6;
}
.sport-bg{
  background: #ae4bff;
}
</style>
